import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    EdsContainerTable,
    EdsForm,
    EdsOptionalLink,
    EdsTableCheckmark,
} from '../../..';
import {
    createProfessionalCaregiverFilterFromTargetData,
    deleteAllOriginSearchParams,
    EdsOriginSearchParam,
    getLogger,
    setEmptyFieldToNull,
    useModal,
    useNavigate,
    useNotifications,
} from '../../../../features';
import _ from 'lodash';
import UmoLinkProfessionalCaregiver from './link-professional-caregiver';
import { EdsSearchEntityType } from './search-step';
import AdditionalInformationFormGroup from './additional-information-form-group';
import { useSearchParams } from 'react-router-dom';

const logger = getLogger('UmoLinkedProfessionalCaregiversTable');

export const UmoLinkedProfessionalCaregiversTable = forwardRef(
    (
        {
            getDataCallback,
            postDataCallback,
            onUnlinkClick,
            getLinkDataCallback,
            putLinkDataCallback,
            visibleHeaderKeys = ['order', 'name', 'kind', 'key', 'available'],
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { showFormModal, showLinkModal } = useModal();
        const { showSuccess, showError } = useNotifications();
        const formRef = useRef(null);
        const [title] = useState(
            props.title ??
                t('b5fc51fe09248958fb552f61b871a8e5', 'Professional caregivers')
        );

        const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
        const [hasData, setHasData] = useState();

        const [searchParams, setSearchParams] = useSearchParams();
        const [searchOpen] = useState(
            searchParams.get(EdsOriginSearchParam.OpenAction)
        );

        const headers = [
            {
                key: 'order',
                header: '#',
                width: '100px',
            },
            {
                key: 'name',
                header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
                sort: 'name',
            },
            {
                key: 'kind',
                header: t('d939aaf7d2291c6f69ceecee94880d7c', 'Kind'),
                sort: 'kind',
            },
            {
                key: 'type',
                header: t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
                sort: 'type',
            },
            {
                key: 'key',
                header: t('3c6e0b8a9c15224a8228b9a98ca1531d', 'Key'),
                width: '100px',
            },
            {
                key: 'address',
                header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
                sort: 'address',
            },
            {
                key: 'available',
                header: t('e4894ca167b08880bfc35862f18575eb', 'Available'),
                width: '100px',
            },
            {
                key: 'isInactive',
                header: t('19d3894f53ce79c3f836f26cf8a3be3b', 'Inactive'),
                width: '100px',
            },
        ];

        const getVisibleHeaders = () => {
            if (!visibleHeaderKeys) {
                return headers;
            }

            const test = visibleHeaderKeys.map((key) =>
                headers.find((h) => h.key === key)
            );

            return test;
        };

        const isVisible = (headerKey) => {
            if (!visibleHeaderKeys) {
                return true;
            }
            return visibleHeaderKeys.includes(headerKey);
        };

        useEffect(() => {
            if (
                !_.isUndefined(hasData) &&
                !_.isUndefined(props?.originTypeName) &&
                searchOpen === props?.originTypeName
            ) {
                const data = JSON.parse(
                    searchParams.get(EdsOriginSearchParam.TargetData)
                );
                const filter =
                    createProfessionalCaregiverFilterFromTargetData(data);

                showProfessionalCaregiverModal({
                    filter: filter,
                    selectedId: data?.id,
                });
                setSearchParams(deleteAllOriginSearchParams(searchParams));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchOpen, hasData]);

        const getData = async () => {
            let data = await getDataCallback();
            setHasData(!_.isEmpty(data));
            return data;
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        const getCrmLinkFormId = (id) => {
            const idParts = id.split('_');
            if (idParts && idParts[1]) {
                return `/crm/${idParts[0]}/${idParts[1]}`;
            } else {
                logger.log('No id prefix found for caregiver');
                return undefined;
            }
        };

        const linkNewCaregiver = async (form) => {
            if (_.isEmpty(form?.linkInfo?.order)) {
                //order can be empty but when empty it must be set to 0 for UDS
                form.linkInfo.order = 0;
            }

            const postData = setEmptyFieldToNull(form);

            const response = await postDataCallback({
                ...(postData?.caregiver?.searchEntity ===
                    EdsSearchEntityType.ProfessionalCaregiver && {
                    professionalCaregiverId: postData.selected,
                }),
                ...(postData?.caregiver?.searchEntity ===
                    EdsSearchEntityType.CareTeam && {
                    careTeamId: postData.selected,
                }),
                order: postData.linkInfo.order,
                hasKey: postData.linkInfo.hasKey,
            });

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '71b89dd2a0ab2c2431141a17a75a2fa0',
                    '{{entity}} linked',
                    {
                        entity: t(
                            '599bfff4492f40356f6e8c9eb47f6996',
                            'Professional caregiver'
                        ),
                    }
                ),
            });

            ref.current?.fetchInitData();

            return response;
        };

        const splitEntityId = (id) => {
            const idParts = id.split('_');
            if (idParts && idParts[1]) {
                return {
                    id: idParts[1],
                    entityType:
                        idParts[0] === 'professional-caregiver'
                            ? EdsSearchEntityType.ProfessionalCaregiver
                            : EdsSearchEntityType.CareTeam,
                };
            }

            return { id: id };
        };

        const showAdditionalInfoModal = async (row) => {
            if (
                !_.isFunction(getLinkDataCallback) ||
                !_.isFunction(putLinkDataCallback)
            ) {
                return;
            }

            const { id, entityType } = splitEntityId(row?.id);

            const idObject = {
                ...(_.isUndefined(entityType) && { id: id }),
                ...(entityType ===
                    EdsSearchEntityType.ProfessionalCaregiver && {
                    professionalCaregiverId: id,
                }),
                ...(entityType === EdsSearchEntityType.CareTeam && {
                    careTeamId: id,
                }),
            };

            let linkInfo = await getLinkDataCallback(idObject);

            const formData = {
                ...idObject,
                linkInfo: {
                    order: { value: linkInfo?.order ?? linkInfo?.sortIndex },
                    ...(isVisible('hasKey') && {
                        hasKey: { value: linkInfo.hasKey },
                    }),
                },
            };

            showFormModal({
                title: t(
                    '419758a02d3e4708e30575803da07347',
                    'Edit professional caregiver link'
                ),
                formData: formData,
                formRef,
                children: (
                    <EdsForm
                        hideDefaultSubmit={true}
                        ref={formRef}
                        initValues={formData}
                        onSubmit={async (_event, form, isValid) => {
                            if (!isValid) {
                                throw false;
                            }

                            try {
                                const response = await putLinkDataCallback(
                                    form
                                );

                                showSuccess({
                                    title: t(
                                        '536a056b710e94b16169efd17a4a657b',
                                        'Saved'
                                    ),
                                    content: t(
                                        '9be8b87979790eba7c8d3ab28acdcf4f',
                                        '{{entity}} link is saved',
                                        {
                                            entity: t(
                                                '599bfff4492f40356f6e8c9eb47f6996',
                                                'Professional caregiver'
                                            ),
                                        }
                                    ),
                                });

                                ref.current?.fetchInitData();

                                return response;
                            } catch (error) {
                                showError({
                                    title: t(
                                        'cb5e100e5a9a3e7f6d1fd97512215282',
                                        'Error'
                                    ),
                                    content: t(
                                        'df0a9d46baf7315909e4389a04786e3d',
                                        'Oops something went wrong'
                                    ),
                                });
                                throw error;
                            }
                        }}
                    >
                        <AdditionalInformationFormGroup
                            initValues={formData}
                            showHasKey={isVisible('hasKey')}
                        />
                    </EdsForm>
                ),
            });
        };

        const showProfessionalCaregiverModal = (targetDataProps) => {
            const rows = ref.current?.getRows();
            const filterIds = {
                professionalCaregiverIds: rows
                    .filter((r) => r?.professionalCaregiverId)
                    .map((r) => r?.professionalCaregiverId),
                careTeamIds: rows
                    .filter((r) => r?.careTeamId)
                    .map((r) => r?.careTeamId),
            };

            showLinkModal({
                title: t(
                    '1ca9561c9db8b74135fb87d8cf5dece3',
                    'Link professional caregiver'
                ),
                tableRef: ref,
                size: 'lg',
                children: (
                    <UmoLinkProfessionalCaregiver
                        {...props}
                        {...targetDataProps}
                        onSubmit={async (form) => {
                            return linkNewCaregiver(form);
                        }}
                        filterIds={filterIds}
                    />
                ),
            });
        };

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                onRowClick={(row) => {
                    navigate(getCrmLinkFormId(row.id));
                }}
                headers={getVisibleHeaders()}
                getDataCallback={getData}
                onEditClick={(row) => {
                    showAdditionalInfoModal(row);
                }}
                editLabel={(row) => {
                    const { entityType } = splitEntityId(row?.id);
                    return entityType ===
                        EdsSearchEntityType.ProfessionalCaregiver
                        ? t(
                              '419758a02d3e4708e30575803da07347',
                              'Edit professional caregiver link'
                          )
                        : t(
                              '51ae70ff61de9b12e8714d14a6c43a7f',
                              'Edit care team link'
                          );
                }}
                unlinkLabel={(row) => {
                    const { entityType } = splitEntityId(row?.id);
                    return entityType ===
                        EdsSearchEntityType.ProfessionalCaregiver
                        ? t(
                              '89c0df2ba91af58b16988bdf14e2bd12',
                              'Unlink professional caregiver'
                          )
                        : t(
                              '0fe00259be326f8179f45d05486b678d',
                              'Unlink care team'
                          );
                }}
                onHeaderLinkClick={() => {
                    showProfessionalCaregiverModal();
                }}
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : (row) => {
                              const data = ref.current?.getRowData(row.id);
                              return onUnlinkClick({ row: row, data: data });
                          }
                }
                mappingCallback={(responseData) => {
                    const careId = responseData.careTeamId
                        ? `care-team_${responseData.careTeamId}`
                        : `professional-caregiver_${responseData.professionalCaregiverId}`;

                    return {
                        id: careId,
                        careTeamId: responseData.careTeamId,
                        professionalCaregiverId:
                            responseData.professionalCaregiverId,
                        ...(isVisible('order') && {
                            order: responseData.careRecipientProviderLink
                                ?.order,
                        }),
                        ...(isVisible('name') && {
                            name: (
                                <EdsOptionalLink
                                    id={careId}
                                    to={getCrmLinkFormId(careId)}
                                    label={responseData.careProvider?.name}
                                />
                            ),
                        }),
                        ...(isVisible('kind') && {
                            kind: responseData.careTeamId
                                ? t(
                                      '6e9de986c9d59a1e3f6284d77adbcb3e',
                                      'Care team'
                                  )
                                : responseData.professionalCaregiverId
                                ? t(
                                      '27ade1b64f9515dbb776cc3cc5bc3377',
                                      'Professional'
                                  )
                                : '',
                        }),
                        ...(isVisible('key') && {
                            key: (
                                <EdsTableCheckmark
                                    value={
                                        responseData.careRecipientProviderLink
                                            ?.hasKey
                                    }
                                />
                            ),
                        }),
                        ...(isVisible('address') && {
                            address: responseData.combinedAddressField,
                        }),
                        ...(isVisible('available') && {
                            available: (
                                <EdsTableCheckmark
                                    value={
                                        responseData.careProvider?.isAvailable
                                    }
                                />
                            ),
                        }),
                    };
                }}
                {...props}
            />
        );
    }
);
UmoLinkedProfessionalCaregiversTable.displayName =
    'UmoLinkedProfessionalCaregiversTable';

export const UmoLinkedProfessionalCaregiversTableForCareTeams = forwardRef(
    (props, ref) => {
        const { t } = useTranslation();

        return (
            <UmoLinkedProfessionalCaregiversTable
                {...props}
                ref={ref}
                title={t('d2e3083420929d8bfae81f58fa4594cb', 'Members')}
                editLabel={t(
                    '891b0e4085bd0e7bee1cecff78236faa',
                    'Edit member link'
                )}
                unlinkLabel={t(
                    '464ac3ec1e29efac0e41161cd55d4735',
                    'Unlink member'
                )}
                searchEntitySelection={false}
                visibleHeaderKeys={[
                    'order',
                    'name',
                    'type',
                    'address',
                    'isInactive',
                ]}
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        professionalCaregiverId:
                            responseData?.professionalCaregiverId,
                        order: responseData.sortIndex,
                        name: (
                            <EdsOptionalLink
                                id={responseData.professionalCaregiverId}
                                to={`/crm/professional-caregiver/${responseData.professionalCaregiverId}`}
                                label={responseData.personCombinedName}
                            />
                        ),
                        type: responseData.caregiverTypeName,
                        address: responseData.combinedAddressField,
                        isInactive: (
                            <EdsTableCheckmark
                                value={responseData.isInactive}
                            />
                        ),
                    };
                }}
            />
        );
    }
);
UmoLinkedProfessionalCaregiversTableForCareTeams.displayName =
    'UmoLinkedProfessionalCaregiversTableForCareTeams';
